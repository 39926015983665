@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
#blogNavBar > div > a{
    font-family: 'Balsamiq Sans', cursive;
    font-size: 16px;
    color: #85C9FF;
}
#blogNavBar > div > a:hover{
    transition-duration: 750ms;
    color: #FFFFFF;
}
.nav-header{
    color: #F2F2F2;
    font-family: 'Lobster', cursive;
}
.blog-container{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, minmax(400px, 1000px));
    grid-gap: 20px;
}
.blog-card{
    height: 300px;
    background-color: #FFFFFF;
}
html > *{
    background-color: #EDEDED;
}
.card-header{
    background-color: #FFFFFF;
}