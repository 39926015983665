.panel-body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    padding: 30px;
    margin: 0 auto;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

h3 {
    font-size: 22px;
    font-weight: bold;
    margin-top: 30px;
}

p, ul {
    margin-bottom: 15px;
}

ul {
    margin-left: 20px;
}

b {
    font-weight: bold;
}