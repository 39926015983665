@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.about-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18.4px;
    font-weight: 400;
    max-width: 900px;
    border-bottom: 0.1px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-bottom-style: solid;
    padding-bottom: 5px;
}
