.registration-text {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
    text-align: center;
}

.registration-text a {
    color: #333333;
    text-decoration: none;
    font-weight: bold;
}

.registration-text a:hover {
    text-decoration: underline;
}