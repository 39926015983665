.ftco-section {
    padding: 7em 0;
}

.login-wrap {
    position: relative;
    background: #fff;
    border-radius: 10px;
    max-width: 450px;
    -webkit-box-shadow: 0 10px 34px -15px rgb(0 0 0 / 24%);
    -moz-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, .24);
    box-shadow: 0 10px 34px -15px rgb(0 0 0 / 24%);
}

.login-wrap .icon {
    width: 80px;
    height: 80px;
    background: #1089ff;
    border-radius: 50%;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.login-wrap .icon span {
    color: #fff;
}

.form-group {
    margin-bottom: 1rem;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.form-group > .form-control {
    height: 52px;
    background: #fff;
    min-width: 100%;
    color: #000;
    font-size: 16px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .1);
}

.rounded {
    border-radius: 0.25rem !important;
}

.btn.btn-primary {
    background: #1089ff !important;
    border: 1px solid #1089ff !important;
    color: #fff !important;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #1089ff;
}

.error-box {
    height: 40px
}

.error-text {
    color: red;
}

#submitSignup {
    margin-top: 15px;
}