@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

.bookcard__container {
    border-radius: 2px;
    padding-top: 20px;
    padding-left: 20px;
    background-color: white;
    height: auto;
}

.bookcard__title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
}

.bookcard__author {
    font-size: 14px;
    margin-bottom: 20px;
}

.bookcard__author > a {
    color: #461111
}

.bookcard__author > i {
    font-size: 14px;
    margin-left: 5px;
}

.bookcard__genres {
    cursor: pointer;
}

.bookcard__genres span {
    background-color: #d6d6d6;
    height: auto;
    display: none;
    position: absolute;
    z-index: 2;
    margin-top: 35px;
    padding: 3px 7px;
    font-size: 17px;
    border: 2.5px solid black;
}

.bookcard__genres:hover span {
    display: block;
}

.rating-and-grade-block {
    margin-top: 5px !important;
    padding-bottom: 15px;
}

.rating-block {
    overflow-y: visible !important;
    overflow-x: hidden !important;
}

.rating-number {
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
}

.quantity-of-rating {
    margin-left: 5px;
}

.rating {
    max-width: 50% !important;
}

.grade-block {
    width: auto;
}

.grade-circle {
    margin-right: 3px;
    margin-bottom: 3px;
    height: 50px;
    width: 50px;
    background-color: #F2F2F2;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
}

.grade-text {
    font-family: 'Titan One';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #4FB17C;
}

.cancel-grade-circle {
    cursor: pointer;
}

#cancel-grade {
    width: 50px;
    height: 50px;
    position: relative;
    transform: rotate(45deg);
}

#cancel-grade:before, #cancel-grade:after {
    content: "";
    position: absolute;
    z-index: 3;
    background: #d00;
}

#cancel-grade:before {
    left: 50%;
    width: 30%;
    margin-left: -15%;
    height: 100%;
}

#cancel-grade:after {
    top: 50%;
    height: 30%;
    margin-top: -15%;
    width: 100%;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: unset;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mobile-cancel-grade-button {
    width: 15px;
    height: 15px;
    color: red;
    top: 0;
    right: 0;
}
