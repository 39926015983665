.connect-telegram-container {
    margin-bottom: 200px;
}

.author-list > li > a {
    color: inherit;

}

.author-list > li > a:hover {
    color: #007bff;
}

.unfollow-button {
    height: 38px;
}

.connect-telegram-container {
    margin-top: 200px;
}

.visuallyhidden {
    clip: rect(1px, 1px, 1px, 1px)
}

.copybutton {
    background-color: #fff;
    border: 0;
    outline: 0;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 9;
    border-radius: 24px
}


#custom-tooltip {
    display: none;
    position: absolute;
    top: 35px;
    left: -55px;
    margin-left: 40px;
    padding: 5px 12px;
    background-color: #000000df;
    border-radius: 4px;
    color: #fff;
}

.telegram-code {
    padding: 6px;
    border: 0.5px solid rgba(1, 1, 1, 0.1);
}

.registration-alert {
    margin-top: 250px;
    margin-bottom: 455px;
}