.rating {
    display: flex;
    transition: margin-left 0.75s;

}

.stars__out {
    position: relative;
    margin-right: 5px;
    /* сделаем отступ между зведами */
    z-index: 1;
}

.rating-star {
    transition: transform 0.6s, margin-right 0.6s

}

.stars__in {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    /* ну и ограничим область видимости, а также ширину установим в 0 по умолчанию   */
    overflow: hidden;
    width: 0;
}

.yellow-star, .yellow-star-hover:hover {
    color: #ffd700;
}

.green-star:hover {
    color: rgb(47, 232, 122);

}

.grey-star {
    color: #625f5f;
}

.red-star:hover {
    color: rgb(255, 0, 0);
}

