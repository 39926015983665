.author-name {
    margin-top: 25px;
}

.follow-information {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 15px;
    margin-bottom: 3px;
}

.telegram-section > p {
    font-size: 17px;
}

html {
    height: 100%;
}