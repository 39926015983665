@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import "/node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";

body {
    min-height: 100%;
    margin: 0;
    font-family: "Noto Sans JP", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f5f5;

}

#body {
    background: linear-gradient(180deg, hsla(0, 0%, 89%, 1) 0%, hsla(0, 0%, 100%, 1) 21%) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
    margin: 0;
    padding: 0;
}

.App {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;

}

#root {
    min-height: 100%;
}

footer {
    width: 100%;
    height: 180px;
}

footer > p {
    align-self: center;
}

.btn:focus, .btn:active {
    box-shadow: none !important;
}

div > input {
    margin-bottom: 4px;
}

.custom-select {
    max-width: 150px;
}

#sort_order > .form-select {
    max-width: 150px !important;
}

.show_more {
    margin-top: 50px;
    margin-bottom: 50px;
}

input {
    max-width: 200px;
}

.multiselect {
    width: 200px;
}

.selectBox {
    position: relative;
}

.selectBox select {
    width: 100%;
    font-weight: bold;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#checkboxes {
    display: none;
    border: 1px #dadada solid;
}

#checkboxes label {
    display: block;
}

#checkboxes label:hover {
    background-color: #1e90ff
}

.fa-user-circle {
    color: gray;
}

.fa {
    font-weight: normal;
}