@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@400;700&display=swap');

.footer {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    height: auto;
    width: 100%;
    margin-top: 100px;
}

.footer > .p1 {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    align-self: start;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 27px;

    color: #FFFFFF;
}

.p2 {
    width: 100%;
    padding-top: 10px;
    padding-right: 5px;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    text-align: right;

    color: #FFFFFF;
}

.footer > p > a {
    color: #ffd700;
    font-weight: 700;
}

.footer > p > a:hover {
    color: #1b8738;
    transition: 1s;
    text-decoration: none;
}
