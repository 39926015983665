.p-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#policy {
    padding-left: 30px;
}

.purpose-table, .purpose-table th, .purpose-table td {
    border: 1px solid #d8d8d8;
    font-size: inherit;
    line-height: inherit;
}