html {
    background-color: white;
}

.delete-account {
    margin-top: 227px;
    margin-bottom: 210px;
}

.delete-account-btn {
    margin-top: 200px;
    margin-bottom: 40px;
}
