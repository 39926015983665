.bd-navbar .navbar-nav {
    padding-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
}

.bd-navbar .navbar-nav-scroll {
    max-width: 100%;
    height: 2.5rem;
    margin-top: 0.25rem;
    overflow: hidden;
    font-size: .875rem;
}

.fa-user-circle {
    margin-top: -4px;
}

@media only screen and (min-width: 576px) {
    .nav-ul-scroll {
        width: 100% !important;
    }

    .navbar-nav-scroll {
        width: 100% !important;
    }
}

.ul-scroll {
    white-space: nowrap;
}

.navbar {
    width: 100%;
    background-color: #413c69;
}

.nav-books-search {
    min-width: 300px !important;
    max-height: 35px;
}

.books-search {
    min-width: 200px !important;
    max-width: 75%;
}

.search-container {
    min-height: 100%;
}

.search-switch {
    transform: rotate(90deg);
}

.dropdown-menu {
    right: 0 !important;
    left: auto !important;
}