.submitButton {
    height: 36px
}

.filters {
    margin-bottom: 50px;
}

.genres-list {
    width: 900px
}

.genre-pill {
    background-color: #ddd;
    border: none;
    color: black;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
    font-size: 14px
}

.css-1qprcsu-option, .css-1vr111p-option {
    padding-left: 20px !important;
    font-weight: normal !important;
}

.genre-meta {
    font-weight: bold !important;
    padding-left: 12px !important;
    font-size: 16px;
    margin-left: 0;
}

.resetFilters {
    height: 36px
}

.books-not-found-text {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.show_more {
    margin-top: 200px !important;
}
